import React from 'react'
import styles from './LeftOverlay.module.scss'

const LeftOverlay = props => (
  <div className={styles.wrap}>
    <div className={`${styles.children} fade-in-animation`}>
      {props.children}
    </div>
    <div className={`${styles.overlay} fade-in-animation`} />
  </div>
)

export default LeftOverlay
